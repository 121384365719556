import React from "react";
import InstantBuyLayout from "ps-components/Layout/InstantBuyLayout";
import PropTypes from "prop-types";
import root from "window-or-global";
import withStyles from "@material-ui/core/styles/withStyles";
import toiCardiacCareLogo from "ps-assets/img/toi-cardiac-care-logo.jpg";
import toiCardiacCareHeart from "ps-assets/img/toi-cardiac-heart-img.gif";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import starTitle from "ps-assets/img/toi-cardiac-care-star-title.gif";
import submitButtonImg from "ps-assets/img/toi-cardiac-care-submit-btn.jpg";
import resetButtonImg from "ps-assets/img/toi-cardiac-care-reset-btn.jpg";
import { landingPageLMSCall } from "util/lmsRequest";
import toiCardiacCareStyles from "ps-assets/jss/toiCardiacCareStyle";
import {  browserWindow,browserDocument } from "util/localStorageHelper";

const validationConditions = {
  name: `^[a-zA-Z .]*$`,
  mobile: `^[6-9][0-9]{9}$`,
  pincode: `^[1-9][0-9]{5}$`,
};

const inititalState = {
  name: ``,
  email: ``,
  mobile: ``,
  pincode: ``,
  privacyPolicy: true,
  submitButton: false,
};

class ToiCardiacCare extends React.Component {
  state = inititalState;

  componentDidMount = () => {
    browserDocument.body.style.backgroundColor = `white`;
  };

  handleChange = (e) => {
    switch (e.target.id) {
      case `name`:
          this.setState({ [e.target.id]: e.target.value });
        break;
      case `pincode`:
        if (!isNaN(e.target.value) && e.target.value.length <= 6) {
          this.setState({ [e.target.id]: e.target.value });
        }
        break;
      case `mobile`:
        if (!isNaN(e.target.value) && e.target.value.length <= 10) {
          this.setState({ [e.target.id]: e.target.value });
        }
        break;
      case `otpNumber`:
        if (!isNaN(e.target.value) && e.target.value.length <= 6) {
          this.setState({ [e.target.id]: e.target.value, otpError: `` });
        }
        break;
      default:
        this.setState({ [e.target.id]: e.target.value });
        break;
    }
  };

  handlePrivacyPolicy = () => {
    this.setState({
      privacyPolicy: !this.state.privacyPolicy,
    });
  };

  sendToLMS = async () => {
    try {
      let queryParams = decodeURIComponent(browserWindow.location.search);
      queryParams = queryParams.split(`?`)[1];
      let response = await landingPageLMSCall(
        this.state.name,
        this.state.mobile,
        this.state.email,
        this.state.pincode
      );
      response = await response.json();
      sessionStorage.setItem(`prevLandingPage`, `complete-health-insurance`);
      root.open(
        `${browserWindow.location.pathname}/thank-you?userId=${
          response.zohoEntityId ? response.zohoEntityId : ``
        }${queryParams ? `&${queryParams}` : ``}`,
        `_self`
      );
    } catch (exception) {
      console.error(`except - `, exception);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      submitButton: true,
    });
    if (this.state.name !== ``) {
      if (this.state.email.search(/^\S+@\S+\.\S+$/) === 0) {
        if (this.state.mobile.search(validationConditions.mobile)==0) {
          if (this.state.privacyPolicy) {
            if (this.state.pincode.search(validationConditions.pincode)==0) {
              this.sendToLMS();
            } else alert('Please Enter PinCode');
          } else alert('Read Terms and Conditions');
        } else alert('Enter Mobile Number');
      } else alert('Enter Your Email');
    } else alert('Enter Your Name');
  };

  handleReset = () => {
    this.setState({ ...inititalState });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
      <Helmet key="helmetTags">
        <title>Star Health Insurance: Medical, Accident and Travel insurance policies</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link rel="canonical" href="https://www.starhealth.in/lp/cardiac-care" />
          <meta
            name="title"
            content="Star Health Insurance: Medical, Accident and Travel insurance policies"
          />
          <meta
            name="twitter:title"
            content="Star Health Insurance: Medical, Accident and Travel insurance policies"
          />
          <meta property="og:title" content="Star Health Insurance: Medical, Accident and Travel insurance policies" />
          <meta name="Star Health Insurance: Medical, Accident and Travel insurance policies" />
        </Helmet>
      )}
      <InstantBuyLayout noFooter={true} noHeader={true}>
        <h1   className={classes.h1title} style={{textAlign:'center'}}>Star Cardiac Care Policy - Key features</h1>
        <div className={classes.whiteBg}>
          <div className={classes.customContainer}>
            <div className={classes.header}>
              <img src={toiCardiacCareLogo} alt="cardiac-care-logo" />
              <p>
                <div style={{marginLeft:'0px',marginBottom:'0px'}} className={classNames(classes.middleContent
                // , classes.defaultTitleImage
                  )}>
                <img src={starTitle} alt="star-title-here" style={{width: '87%', height: '19px',marginBottom:'9px'}} />
              </div>
                India`s first Stand-alone health insurance company Star Health and Allied Insurance 
                brings you Star Cardiac care policy, the first
                of its kind for persons who have undergone Heart Surgery. This
                policy provides Health cover for following Heart Surgery
                Procedures - Angioplasty and Cardiac Bypass.
                <br />
              </p>
            </div>
            <div className={classes.middle}>
              {/* <div className={classNames(classes.middleContent,classes.mobileTitleImage)}>
                <img src={starTitle} alt="star-title-here" />
              </div> */}
              <div className={classes.heartImage}>
                <img
                  src={toiCardiacCareHeart}
                  alt="toi-cardiac-care-heart-img"
                />
              </div>
              <div className={classNames(classes.middleContent,classes.defaultTitleImage)}>
                <div className={classes.keyFeature}>
                  <p>Key features</p>
                  <ul>
                    <li>
                      India`s 1st Health policy for persons who have undergone
                      Angioplasty or Bypass Surgery
                    </li>
                    <li>Lifetime renewals</li>
                    <li>
                      Also provides health cover for regular hospitalization
                      &amp; accidental injuries
                    </li>
                    <li>Medical Management for Cardiac Ailments*</li>
                    <li>101 Day care procedures covered</li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <form>
                <div className={classes.form}>
                  <div className={classes.notice}>
                    Fill in the details to know more about our Cardiac Care
                    Policy.
                  </div>
                  <div className={classes.fieldContainer}>
                    <div className={classes.textBox}>
                      <label>
                        Name<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className={classes.textBox}>
                      <label>
                        Email<span>*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className={classes.fieldContainer}>
                    <div className={classes.textBox}>
                      <label>
                        Mobile No<span>*</span>
                      </label>
                      <input
                        type="text"
                        id="mobile"
                        name="mobile"
                        value={this.state.mobile}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className={classes.textBox}>
                      <label>
                        PinCode<span>*</span>
                      </label>
                      <input
                        type="text"
                        id="pincode"
                        name="pincode"
                        value={this.state.pincode}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className={classes.privacyPolicy}>
                    <p>
                      <input
                        type="checkbox"
                        onClick={this.handlePrivacyPolicy}
                        checked={this.state.privacyPolicy}
                      />
                      I have read and agree to the
                      <a
                        href="/static-privacy-policy"
                        target="_blank"
                        style={{
                          cursor: `pointer`,
                          textDecoration: "underline",
                          margin: `0px 3px`,
                        }}
                        rel={`noopener noreferrer`}
                      >
                        Privacy Policy
                      </a>
                      I hereby authorize Starhealth to call me.
                    </p>
                    <label>
                      <span>*</span> Mandatory Fields
                    </label>
                  </div>
                  <div className={classes.buttonsDiv}>
                    <a onClick={() => {}}>
                      <img
                        src={submitButtonImg}
                        alt="submit-button"
                        onClick={this.handleSubmit}
                      />
                    </a>
                    <a onClick={() => {}}>
                      <img
                        src={resetButtonImg}
                        alt="submit-button"
                        onClick={this.handleReset}
                      />
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={classes.footer}>
            <div
              className={classes.customContainer}
            >
              <p>IRDAI REGISTRATION NUMBER. 129</p>
              <p>
                Insurance is subject matter of solicitation | For more details
                on risk factors, terms and conditions, Please read the sales
                brochure carefully before concluding a sale | SHAI/13-14/WEB/001
              </p>
              <p>
                <strong>
                  Star Health and Allied Insurance Company Limited.
                </strong>
                <br />
                Corporate Office: No.1, New Tank Street, Valluvar Kottam High
                Road, Nungambakkam, Chennai - 600034. Sales & Services - 044 4674 5800
              </p>
              <p>
                For further details visit{" "}
                <a
                  href="http://www.starhealth.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration:`underline`
                  }}
                >
                  www.starhealth.in
                </a>
              </p>
            </div>
          </div>
        </div>
      </InstantBuyLayout>
      </>
    );
  }
}

ToiCardiacCare.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(toiCardiacCareStyles)(ToiCardiacCare);
