import {
  mlAuto,
  mrAuto,
  mBAuto,
  mTAuto,
  title
} from "assets/jss/material-kit-pro-react.jsx";

const toiCardiacCareStyles = function(theme) {
  return {
    mlAuto,
    mrAuto,
    mBAuto,
    mTAuto,
    whiteBg: {
      backgroundColor: `white`,
      [theme.breakpoints.down(`md`)]: {
        marginLeft: `unset`,
        marginRight: `unset`,
      },
    },
    customContainer: {
      [theme.breakpoints.up(`lg`)]:{
        width: `71%`,
        ...mlAuto,
        ...mrAuto,
      }
    },
    header: {
      display: `inline-flex`,
      "& img": {
        width: `245px`,
        height: `116px`,
        [theme.breakpoints.down(`sm`)]:{
          width: `auto`,
          height: `auto`
        }
      },
      "& p": {
        marginLeft: `8%`,
        fontSize: `12px`,
        height: `100%`,
        ...mBAuto,
        ...mTAuto,
        color: `#666666`,
        padding: `15px`,
        lineHeight: `14px`,
        fontFamily: `Georgia, "Times New Roman", Times, serif`,
        [theme.breakpoints.down(`md`)]: {
          lineHeight: `20px`,
          fontSize: `18px`
        },
        // [theme.breakpoints.down(`sm`)]: {
        //   lineHeight: `24px`,
        //   fontSize: `18px`,
        //   marginLeft: `0px`,
        //   marginTop: `3px`
        // },
        [theme.breakpoints.down(`xs`)]: {
          lineHeight: `25px`,
          fontSize: `16px`,
          marginLeft: `0px`,
          marginTop: `3px`
        },
      },
      [theme.breakpoints.down(`sm`)]:{
        width:`auto`,
        display: `block`
      }
    },
    h1title:{
      ...title,
      fontSize:'38px'
      },
    middle: {
      display: `inline-flex`,
      [theme.breakpoints.down(`sm`)]:{
        paddingLeft:`10px`,
        paddingRight: `10px`,
        display: `block`
      }
    },
    heartImage: {
      width: `503px`,
      height: `396px`,
      float: `left`,
      [theme.breakpoints.down(`sm`)]:{
        width: `100%`,
        height: `auto`,
        float: `none`,
        "& img":{
          width: `inherit`,
          height: `auto`,
        }
      },
    },
    middleContent: {
      height: `100%`,
      width: `412px`,
      ...mTAuto,
      ...mBAuto,
      "& img": {
        marginBottom: `25px`,
      },
      [theme.breakpoints.down(`sm`)]:{
        width: `100%`,
        marginBottom: `0px`,
          "& img": {
            marginBottom: `25px`,
            width: `inherit`,
            marginTop: `5%`
          }
      },
      [theme.breakpoints.up(`md`)]:{
        marginLeft: `5%`
      }
    },
    keyFeature: {
      "& p": {
        borderBottom: `1px solid #e7ecee`,
        fontWeight: `bold`,
        fontFamily: `Georgia, "Times New Roman", Times, serif`,
        [theme.breakpoints.down(`sm`)]: {
          fontSize: `18px`,
          marginTop: `25px`,
          lineHeight: `24px`,
          marginLeft: `10px`
        },
        [theme.breakpoints.down(`xs`)]:{
          fontSize: `19px`,
          color: `#333333`,
          fontWeight: `100`,
          marginBottom: `5%`
        },
        [theme.breakpoints.up(`md`)]:{
          fontSize: `20px`,
          fontWeight: `400`,
          marginBottom: `25px`
        }
      },
      "& ul": {
        listStyle: `none`,
        [theme.breakpoints.down(`sm`)]:{
          paddingLeft:`20px`,
          "& li":{
            fontSize: `16px`,
            lineHeight: `17px`,
            color: `#666666`,
            fontFamily: `Arial, Helvetica, sans-serif`,
            paddingBottom: `10px`
          }
        },
        [theme.breakpoints.down(`xs`)]:{
          paddingLeft:`10px`,
          "& li":{
            fontSize: `14px`,
            lineHeight: `17px`,
            color: `#666666`,
            fontFamily: `Arial, Helvetica, sans-serif`,
            paddingBottom: `10px`
          }
        },
        [theme.breakpoints.up(`md`)]:{
          paddingInlineStart: `10px`,
          "& li":{
            fontFamily: `Arial, Helvetica, sans-serif`,
            color: `#666666`,
            fontSize: `14px`,
            lineHeight: `16px`,
            marginBottom: `10px`
          }
        }
      },
      borderBottom: `1px solid #e7ecee`,
    },
    form: {
      border: `1px solid #5996ca`,
      margin: `36px 0 41px 0`,
      boxShadow: `3px 3px 5px #888888`,
      borderRadius: `5px`,
      padding: `18px 10px`,
      fontSize: `14px`,
      color: `#666666`,
      [theme.breakpoints.down(`sm`)]:{
        margin: `10px`,
        marginTop: `20px`,
        marginRight: `15px`,
        paddingRight: `20px`
      },
      [theme.breakpoints.down(`xs`)]:{
        marginRight: `10px`,
        padding: `18px 10px`,
      }
    },
    notice: {
      marginRight: `1%`,
      marginLeft: `1%`,
      fontSize: `16px`,
      color: `#333333`,
      fontWeight: `500`,
      [theme.breakpoints.up(`md`)]:{
        marginBottom: `8px`
      }
    },
    fieldContainer: {
      display: `flex`, marginTop: `0%`, marginBottom: `1%`, 
      [theme.breakpoints.down(`sm`)]: {
        marginTop: `0px`,
        marginBottom: `2px`,
        display: `block`
      },
    },
    privacyPolicy: {
      marginRight: `1%`,
      marginLeft: `1%`,
      "& label": {
        color: `#666666`,
        "& span": {
          color: `red`,
        },
        [theme.breakpoints.down(`sm`)]:{
          fontSize: `12px`
        },
      },
      "& p":{
        fontFamily: `Arial, Helvetica, sans-serif`,
        fontSize: `12px`,
        color: `#666666`,
        marginBottom:`0px`,
      },
      [theme.breakpoints.down(`sm`)]:{
        marginTop: `10px`,
        "& p":{
          "& input":{
            marginRight: `5px`
          },
        }
      }
    },
    textBox: {
      width: `48%`,
      marginRight: `1%`,
      marginLeft: `1%`,
      "& input": {
        backgroundColor: `transparent`,
        padding: `0 5px`,
        fontSize: `12px`,
        color: `#666666`,
        height: `26px`,
        width: `100%`,
        "&:focus": {
          outline: `-webkit-focus-ring-color auto 1px`,
        },
      },
      "& select": {
        backgroundColor: `transparent`,
        padding: `0 5px`,
        fontSize: `12px`,
        color: `#666666`,
        height: `26px`,
        width: `100%`,
      },
      "& label": {
        color: `#666666`,
        "& span": {
          color: `red`,
        },
      },
      [theme.breakpoints.down(`sm`)]:{
        width: `100%`
      }
    },
    buttonsDiv: {
      "& a":{
        cursor: `pointer`,
        marginRight: `1%`,
        width: `100%`
      },
      [theme.breakpoints.down(`sm`)]:{
        width: `fit-content`,
        display: `flex`,
        ...mlAuto,
        ...mrAuto,
        marginTop: `5px`
      },
      [theme.breakpoints.up(`md`)]:{
        padding:`4px 10px`
      }
    },
    footer: {
      textAlign: `center`,
      backgroundColor: `#ececec`,
      color: `#666666`,
      fontFamily: `Arial, Helvetica, sans-serif`,
      paddingTop: `12px`,
      paddingBottom: `12px`,
      "& p":{
        fontSize: `12px`,
        ...mrAuto,
        ...mlAuto,
        marginBottom: `6px`,
        lineHeight: `16px`
      },
      [theme.breakpoints.down(`sm`)]:{
        marginTop: `13px`,
        "& p":{
          fontSize: `12px`,
          width: `97%`,
          lineHeight: `16px`
        },
      }
    },
    mobileTitleImage:{
      [theme.breakpoints.up(`md`)]:{
        display: `none`
      }
    },
    defaultTitleImage:{
      [theme.breakpoints.down(`sm`)]:{
        "& img":{
          display: `none`
        }
      }
    }
  };
};

export default toiCardiacCareStyles;
